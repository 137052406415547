.about-container h1 {
    font-size: 9.5rem;
    font-weight: 700;
    color: #343434;
    font-family: "Montserrat", sans-serif;
    text-decoration: underline;
  }

  .about-container{
      margin-top: 5rem;
      overflow: hidden;
  }

  @media screen and (max-width: 1180px) {
    .about-container h1 {
      font-size: 8rem;
    }
}

@media screen and (max-width: 768px) {
    .about-container h1 {
      font-size: 6rem;
    }
}

@media screen and (max-width: 489px) {
    .about-container h1 {
      font-size: 3.8rem;
    }
}