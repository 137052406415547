::-moz-selection { /* Code for Firefox */
    color: white;
    background: #343434;
  }
  
  ::selection {
    color: white;
    background: #343434;
  }

.flexSB{
    display: flex;
    justify-content: space-between;
}